export class GlobalUser {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
  email: string;
  is_active: boolean;
  is_superuser: boolean;
  trial_user?: boolean;
  // groups?: [];
  groups: Groups[];
  date_joined: string;
  configs?: UserConfig;
  number_of_notifications?: number;
  organization?: Organiztion;
}

export class Groups {
  id: number;
  name: string;
  permissions: [];
}

export class UserDashboardFiles {
  results: UserDashboard;
  total: number;
  url_token: string;
}

export class UserDashboard {
  keywords: [];
  files: UserFiles[];
  numbers: UserFileStats[];
}

export class UserFiles {
  filename: string;
  id: number;
  keyword_tags: [];
  last_saved: number;
  modified_time: string;
  original_filename: string;
  page_label_start: number;
  processed: boolean;
  status: string;
  thumbnail: string;
  total_pages: number;
  zoom_level: number;
}

export class UserFileStats {
  name: string;
  value: number;
}

export class UserConfig {
  auto_save: boolean;
  math_api: boolean;
  chem_api?: boolean;
  mathml?: boolean;
  latex?: boolean;
  state_diagram?: boolean;
  tag_form: boolean;
  docx_download: boolean;
  epub_download: boolean;
  html_download: boolean;
  enable_manage_accounts: boolean;
}

export class Organiztion {
  id: number;
  logo: string;
  name: string;
  org_type: string;
}
