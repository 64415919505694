<div fxLayout="row" class="prep-auth">
  <div fxLayout="column" class="page" fxFlex="55" fxHide.lt-md="true"></div>
  <div fxLayout="column" fxLayoutAlign="center center" style="background-color: rgb(255, 255, 255)" fxFlex="45"
    fxFlex.lt-md="100" class="elevation" fxLayoutGap="10px">
    <div fxFlex="20" fxLayoutAlign="start center" style="
        background-color: rgb(255, 255, 255);
        width: 100%;
        padding-left: 5%;
      ">
      <img src="../../../../assets/splash-screen/prep-icon.svg" height="90" />&nbsp;&nbsp;
      <span style="font-size: x-large; font-weight: bold">
        PDF Remediation Platform</span>
    </div>
    <div fxFlex="70" fxLayoutAlign="center center" style="width: 100%; padding-left: 5%; padding-right: 5%">
      <div [@fadeInUp] class="card border-radius" style="height: 60vh">
        <form>
          <div fxLayout="column">
            <div style="padding-bottom: 30px">
              <!-- <img src="../../../../assets/Logo/miami-university-logo.png" height="90">  -->
              <img src="../../../../assets/Logo/ce-logo.svg" height="90"> 
              <h1>USER LOGIN</h1>
              <!-- <h3 style="color: #136ef8">Welcome to PREP Console</h3> -->
            </div>
            <button class="platform-login-button" type="submit" id="submit" color="primary" mat-raised-button
              [disabled]="is_loading" (click)="send()">
              SIGN IN
            </button>


            <div fxLayout="row" fxLayoutAlign="center center" style="padding: 15px 0">
              <mat-spinner color="primary" [diameter]="40" fxLayout="row" fxLayoutAlign="center center"
                *ngIf="is_loading">
              </mat-spinner>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div fxFlex="10" fxLayoutAlign="center center" style="width: 100%; padding-left: 5%">
      <div fxLayout="column">
        <span><small>Powered by</small></span>
        <img src="../../../../assets/Logo/ce-logo.svg" height="50" />
      </div>
    </div>
  </div>
</div>