import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment.prod';
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
  });
  baseUrl: string = environment.backend;

  constructor(private http: HttpClient) {}

  exportTags(fd): Observable<any> {
    return this.http.post(
      this.baseUrl + 'v1/tags/export-pdf/',
      fd,
      this.getAuthHeaders()
    );
  }

  getAllJobList(): Observable<any> {
    return this.http.get(this.baseUrl + 'pdf/uploads/', this.getAuthHeaders());
  }
  createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }
  // pageChangeJobList(offset,limit): Observable<any> {
  //   return this.http.get(this.baseUrl + 'pdf/uploads/?offset=' +offset  +  '&limit=' + limit , this.getAuthHeaders());
  // }
  pageChangeJobList(offset, limit, query_string): Observable<any> {
    return this.http.get(
      this.baseUrl +
        'pdf/uploads/?offset=' +
        offset +
        '&limit=' +
        limit +
        '&query_string=' +
        query_string,
      this.getAuthHeaders()
    );
  }
  searchJobList(offset, limit, query_string): Observable<any> {
    return this.http.get(
      this.baseUrl +
        'pdf/uploads/?offset=' +
        offset +
        '&limit=' +
        limit +
        '&query_string=' +
        query_string,
      this.getAuthHeaders()
    );
  }

  AllfilterSearchJobList(
    offset,
    limit,
    query_string,
    fileStatus,
    fileLabels,
    fileShared
  ): Observable<any> {
    if (fileShared === true) {
      if (query_string !== null && fileStatus !== null && fileLabels !== null) {
        // 111 - 1 // Order query_string, FileStatus, FileLabels
        return this.http.get(
          this.baseUrl +
            'pdf/uploads/?offset=' +
            offset +
            '&limit=' +
            limit +
            '&query_string=' +
            query_string +
            '&file_status=' +
            fileStatus +
            '&label=' +
            fileLabels +
            '&file_shared=' +
            true,
          this.getAuthHeaders()
        );
      } else if (
        query_string === null &&
        fileStatus === null &&
        fileLabels === null
      ) {
        // 000 - 2
        return this.http.get(
          this.baseUrl +
            'pdf/uploads/?offset=' +
            offset +
            '&limit=' +
            limit +
            '&file_shared=' +
            true,
          this.getAuthHeaders()
        );
      } else if (
        query_string !== null &&
        fileStatus === null &&
        fileLabels === null
      ) {
        // 100 -3
        return this.http.get(
          this.baseUrl +
            'pdf/uploads/?offset=' +
            offset +
            '&limit=' +
            limit +
            '&query_string=' +
            query_string +
            '&file_shared=' +
            true,
          this.getAuthHeaders()
        );
      } else if (
        query_string === null &&
        fileStatus !== null &&
        fileLabels === null
      ) {
        // 010 - 4
        return this.http.get(
          this.baseUrl +
            'pdf/uploads/?offset=' +
            offset +
            '&limit=' +
            limit +
            '&file_status=' +
            fileStatus +
            '&file_shared=' +
            true,
          this.getAuthHeaders()
        );
      } else if (
        query_string === null &&
        fileStatus === null &&
        fileLabels !== null
      ) {
        // 001 - 5
        return this.http.get(
          this.baseUrl +
            'pdf/uploads/?offset=' +
            offset +
            '&limit=' +
            limit +
            '&label=' +
            fileLabels +
            '&file_shared=' +
            true,
          this.getAuthHeaders()
        );
      } else if (
        query_string !== null &&
        fileStatus !== null &&
        fileLabels === null
      ) {
        // 110 - 6
        return this.http.get(
          this.baseUrl +
            'pdf/uploads/?offset=' +
            offset +
            '&limit=' +
            limit +
            '&query_string=' +
            query_string +
            '&file_status=' +
            fileStatus +
            '&file_shared=' +
            true,
          this.getAuthHeaders()
        );
      } else if (
        query_string === null &&
        fileStatus !== null &&
        fileLabels !== null
      ) {
        // 011 - 7
        return this.http.get(
          this.baseUrl +
            'pdf/uploads/?offset=' +
            offset +
            '&limit=' +
            limit +
            '&file_status=' +
            fileStatus +
            '&label=' +
            fileLabels +
            '&file_shared=' +
            true,
          this.getAuthHeaders()
        );
      } else if (
        query_string !== null &&
        fileStatus === null &&
        fileLabels !== null
      ) {
        // 101 - 8
        return this.http.get(
          this.baseUrl +
            'pdf/uploads/?offset=' +
            offset +
            '&limit=' +
            limit +
            '&query_string=' +
            query_string +
            '&label=' +
            fileLabels +
            '&file_shared=' +
            true,
          this.getAuthHeaders()
        );
      }
    } else {
      if (query_string !== null && fileStatus !== null && fileLabels !== null) {
        // 111 - 1 // Order query_string, FileStatus, FileLabels
        return this.http.get(
          this.baseUrl +
            'pdf/uploads/?offset=' +
            offset +
            '&limit=' +
            limit +
            '&query_string=' +
            query_string +
            '&file_status=' +
            fileStatus +
            '&label=' +
            fileLabels,
          this.getAuthHeaders()
        );
      } else if (
        query_string === null &&
        fileStatus === null &&
        fileLabels === null
      ) {
        // 000 - 2
        return this.http.get(
          this.baseUrl + 'pdf/uploads/?offset=' + offset + '&limit=' + limit,
          this.getAuthHeaders()
        );
      } else if (
        query_string !== null &&
        fileStatus === null &&
        fileLabels === null
      ) {
        // 100 -3
        return this.http.get(
          this.baseUrl +
            'pdf/uploads/?offset=' +
            offset +
            '&limit=' +
            limit +
            '&query_string=' +
            query_string,
          this.getAuthHeaders()
        );
      } else if (
        query_string === null &&
        fileStatus !== null &&
        fileLabels === null
      ) {
        // 010 - 4
        return this.http.get(
          this.baseUrl +
            'pdf/uploads/?offset=' +
            offset +
            '&limit=' +
            limit +
            '&file_status=' +
            fileStatus,
          this.getAuthHeaders()
        );
      } else if (
        query_string === null &&
        fileStatus === null &&
        fileLabels !== null
      ) {
        // 001 - 5
        return this.http.get(
          this.baseUrl +
            'pdf/uploads/?offset=' +
            offset +
            '&limit=' +
            limit +
            '&label=' +
            fileLabels,
          this.getAuthHeaders()
        );
      } else if (
        query_string !== null &&
        fileStatus !== null &&
        fileLabels === null
      ) {
        // 110 - 6
        return this.http.get(
          this.baseUrl +
            'pdf/uploads/?offset=' +
            offset +
            '&limit=' +
            limit +
            '&query_string=' +
            query_string +
            '&file_status=' +
            fileStatus,
          this.getAuthHeaders()
        );
      } else if (
        query_string === null &&
        fileStatus !== null &&
        fileLabels !== null
      ) {
        // 011 - 7
        return this.http.get(
          this.baseUrl +
            'pdf/uploads/?offset=' +
            offset +
            '&limit=' +
            limit +
            '&file_status=' +
            fileStatus +
            '&label=' +
            fileLabels,
          this.getAuthHeaders()
        );
      } else if (
        query_string !== null &&
        fileStatus === null &&
        fileLabels !== null
      ) {
        // 101 - 8
        return this.http.get(
          this.baseUrl +
            'pdf/uploads/?offset=' +
            offset +
            '&limit=' +
            limit +
            '&query_string=' +
            query_string +
            '&label=' +
            fileLabels,
          this.getAuthHeaders()
        );
      }
    }
  }

  filterAllJobList(offset, limit, fileStatus, fileLabels): Observable<any> {
    return this.http.get(
      this.baseUrl +
        'pdf/uploads/?offset=' +
        offset +
        '&limit=' +
        limit +
        '&file_status=' +
        fileStatus +
        '&label=' +
        fileLabels,
      this.getAuthHeaders()
    );
  }

  filterByFileStatusJobList(offset, limit, fileStatus): Observable<any> {
    return this.http.get(
      this.baseUrl +
        'pdf/uploads/?offset=' +
        offset +
        '&limit=' +
        limit +
        '&file_status=' +
        fileStatus,
      this.getAuthHeaders()
    );
  }

  filterByFileLabelsJobList(offset, limit, fileLabels): Observable<any> {
    return this.http.get(
      this.baseUrl +
        'pdf/uploads/?offset=' +
        offset +
        '&limit=' +
        limit +
        '&label=' +
        fileLabels,
      this.getAuthHeaders()
    );
  }

  downloadFileOriginal(job_id): Observable<any> {
    return this.http.get(
      this.baseUrl + 'accounts/export-filesource/?file_source_id=' + job_id,
      this.getAuthHeaders()
    );
  }
  // deleteFile(job_id): Observable<any> {
  //   return this.http.post(this.baseUrl + 'pdf-content/file-remove/' , job_id, this.getAuthHeaders());
  // }
  deleteFile(job_id): Observable<any> {
    return this.http.post(
      this.baseUrl + 'pdf-content/file-remove/',
      job_id,
      this.getAuthHeaders()
    );
  }

  updateMetaTagsInfo(fd): Observable<any> {
    // return this.http.patch(this.baseUrl + 'v1/tags/job/' + id + '/', fd, this.getAuthHeaders());
    return this.http.post(
      this.baseUrl + 'pdf-content/pdf/keywords/',
      fd,
      this.getAuthHeaders()
    );
  }

  getAllFileLabelKeywords(): Observable<any> {
    // return this.http.patch(this.baseUrl + 'v1/tags/job/' + id + '/', fd, this.getAuthHeaders());
    return this.http.get(
      this.baseUrl + 'pdf-content/pdf/keywords/',
      this.getAuthHeaders()
    );
  }

  getAPIUsuageData(): Observable<any> {
    // return this.http.patch(this.baseUrl + 'v1/tags/job/' + id + '/', fd, this.getAuthHeaders());
    return this.http.get(
      this.baseUrl + 'analytics/data/api/',
      this.getAuthHeaders()
    );
  }

  getFileUploadsUsuageData(): Observable<any> {
    return this.http.get(
      this.baseUrl + 'dashboard/uploads-data/',
      this.getAuthHeaders()
    );
  }

  getFileUploadCount(): Observable<any> {
    return this.http.get(
      this.baseUrl + 'dashboard/uploadcount/',
      this.getAuthHeaders()
    );
  }

  getAPICount(): Observable<any> {
    return this.http.get(
      this.baseUrl + 'dashboard/apicount/',
      this.getAuthHeaders()
    );
  }

  getUserTimeSpent(): Observable<any> {
    return this.http.get(
      this.baseUrl + 'accounts/log-event/',
      this.getAuthHeaders()
    );
  }

  logoutCuncurrentUser(fg): Observable<any> {
    return this.http.post(
      this.baseUrl + 'accounts/logout/',
      fg,
      this.getAuthHeaders()
    );
  }

  private getAuthHeaders() {
    const token = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      Authorization: 'Token ' + token,
      'Content-Type': 'application/json; charset=utf-8',
    });
    return { headers: httpHeaders };
  }
}
