import {
  Component,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SidenavItem } from './sidenav-item/sidenav-item.interface';
import { SidenavService } from './sidenav.service';
import { ThemeService } from '../../../@prep/services/theme.service';

import { GlobalUser } from '../model/global-user';
import { Subscription } from 'rxjs';
import { PlatformGlobalService } from '../service/platform-global.service';
import { CurrentUserService } from '../../pages/authentication/service/current-user.service';
import { DashboardService } from 'src/app/pages/dashboard/service/dashboard.service';

@Component({
  selector: 'prep-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  providers: [CurrentUserService],
})
export class SidenavComponent implements OnInit, OnDestroy {
  account: GlobalUser = new GlobalUser();
  userSub: Subscription;
  account_group = [];
  account_user_name: string;
  account_user_email: string;

  public Current_User: GlobalUser;

  accountOrg: string;

  sidenavUserVisible$ = this.themeService.config$.pipe(
    map((config) => config.sidenavUserVisible)
  );

  @Input()
  @HostBinding('class.collapsed')
  collapsed: boolean;

  @Input()
  @HostBinding('class.expanded')
  expanded: boolean;

  items$: Observable<SidenavItem[]>;


  constructor(
    private router: Router,
    private platformglobalService: PlatformGlobalService,
    private sidenavService: SidenavService,
    private currentUserService: CurrentUserService,
    private themeService: ThemeService,
    private dashboardService: DashboardService,
  ) {}

  ngOnInit() {
    if (localStorage.getItem('token') && localStorage.getItem('account')) {
      this.platformglobalService.me = JSON.parse(
        localStorage.getItem('account')
      );
    }

    this.items$ = this.sidenavService.items$.pipe(
      map((items: SidenavItem[]) =>
        this.sidenavService.sortRecursive(items, 'position')
      )
    );

    this.userSub = this.platformglobalService.user.subscribe((me) => {
      console.log('me', me);
      this.account = me;
      this.account_user_name =
        this.account.first_name + ' ' + this.account.last_name;
      this.account_user_email = this.account.email;
    });

    this.currentUserService.GetCurrentUser().subscribe(
      (response) => {
        this.Current_User = response;
        this.platformglobalService.setCUserInfo = response;
        const accEmail = this.Current_User.username;
        const accDomain = accEmail.replace(/.*@/, '');

        this.sidenavService.addItems([
          {
            name: 'MAIN MENU',
            position: 5,
            type: 'subheading',
            customClass: 'first-subheading',
          },
          {
            name: 'Dashboard',
            routeOrFunction: '/dashboard',
            icon: 'dashboard',
            position: 10,
            pathMatchExact: true,
          },
          {
            name: 'File Upload',
            routeOrFunction: '/file-upload',
            icon: 'cloud_upload',
            position: 15,
          },
          {
            name: 'Exports',
            routeOrFunction: '/exports',
            icon: 'cloud_done',
            position: 25,
          },
        ]);

        if (this.sidenavService.items.length > 0) {
          this.sidenavService.items.forEach((element, index) => {
            if (element.name === 'Users' || element.name === 'API Usage') {
              this.sidenavService.items.splice(index, 1);
            }
          });
        }

        if (this.Current_User.is_superuser === true) {
          if (this.Current_User.configs.enable_manage_accounts === true) {
            this.sidenavService.addItems([
              {
                name: 'Users',
                routeOrFunction: '/users',
                icon: 'manage_accounts',
                position: 20,
                pathMatchExact: true,
              },
              {
                name: 'File Uploads Usage',
                routeOrFunction: '/file-uploads-count',
                icon: 'analytics',
                position: 30,
              },
              {
                name: 'API Usage',
                routeOrFunction: '/api-usage',
                icon: 'data_usage',
                position: 35,
              },
              {
                name: 'Report',
                routeOrFunction: '/report',
                icon: 'report',
                position: 40,
              }
            ]);
          } else {
            this.sidenavService.addItems([
              {
                name: 'File Uploads Usage',
                routeOrFunction: '/file-uploads-count',
                icon: 'analytics',
                position: 30,
              },
              {
                name: 'API Usage',
                routeOrFunction: '/api-usage',
                icon: 'data_usage',
                position: 35,
              },
              {
                name: 'Report',
                routeOrFunction: '/report',
                icon: 'report',
                position: 40,
              },
            ]);
          }
        }
      },
      (error) => {
        this.logoutClicked();
      }
    );
    this.items$ = this.sidenavService.items$.pipe(
      map((items: SidenavItem[]) =>
        this.sidenavService.sortRecursive(items, 'position')
      )
    );
  }


  logoutClicked() {
    let sendUserInfo = {
      username: this.account_user_email,
    };
    this.dashboardService.logoutCuncurrentUser(sendUserInfo).subscribe(
      (response) => {
        if (response.msg) {
          localStorage.removeItem('token');
          localStorage.removeItem('account');
          this.sidenavService.items = [];
          this.router.navigate(['/login']);
        }
      },
      (error) => {
        console.log('error', error);
        localStorage.removeItem('token');
        localStorage.removeItem('account');
        this.sidenavService.items = [];
        this.router.navigate(['/login']);
      }
    );
  }
  
  profile() {
    console.log('welcome to profile');
    this.router.navigate(['/profile']);
  }
  toggleCollapsed() {
    this.sidenavService.toggleCollapsed();
  }

  @HostListener('mouseenter')
  @HostListener('touchenter')
  onMouseEnter() {
    this.sidenavService.setExpanded(true);
  }

  @HostListener('mouseleave')
  @HostListener('touchleave')
  onMouseLeave() {
    this.sidenavService.setExpanded(false);
  }

  ngOnDestroy() {}
}
