export const environment = {
  production: true,
  // Firebase Https PRODUCTION URL:  https://console.prep.continualengine.com/
  // backend: 'https://api.prep.continualengine.com/'
  //Latest
  backend: 'https://prepapi.web.continualengine.com/',

  // AWS Bit Bucket Http STAGING URL: http://remediation-invicta.s3-website-us-east-1.amazonaws.com/
  // backend: 'http://52.188.109.81/' V1
  // backend: 'http://20.124.129.175/' V2

  // AWS Bit Bucket Http TESTING URL:  http://clone-prep-remediation.s3-website-us-east-1.amazonaws.com/
  // backend: 'http://52.163.93.28/'
  // backend: 'https://cepoc.web.moocpedia.com/',

    // DEV PREP
    // backend: 'https://clonecepoc.web.moocpedia.com/',

  // NEW BACKEND IP V3
  // backend: 'http://20.127.116.232/'
  // backend: 'http://20.124.22.218/',
  // backend: 'http://20.228.190.11/',

  // RAVI BACKEND IP
  // backend: 'http://40.76.230.121/',
};
