import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/authentication/login/login.module').then(
        (m) => m.LoginModule
      ),
  },
  {
    path: 'auth/sso-mard/:etaToken',
    loadChildren: () =>
      import('./pages/authentication/login/login.module').then(
        (m) => m.LoginModule
      ),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import(
        './pages/authentication/forgot-password/forgot-password.module'
      ).then((m) => m.ForgotPasswordModule),
  },
  {
    path: 'Y2hhbmdlX3Bhc3N3b3Jk/:userToken/:alphanumeric',
    loadChildren: () =>
      import(
        './pages/authentication/reset-password/reset-password.module'
      ).then((m) => m.ResetPasswordModule),
  },
  {
    path: 'collaborate/:id',
    loadChildren: () =>
      import('./pages/collaborate/collaborate.module').then(
        (m) => m.CollaborateModule
      ),
  },
  {
    path: 'pdf-remediation/:id',
    loadChildren: () =>
      import('./pages/pdf-remediation/pdf-remediation.module').then(
        (m) => m.PdfRemediationModule
      ),
  },
  {
    path: 'pdf-remediation-ocr-scan-mode/:id',
    loadChildren: () =>
      import(
        './pages/pdf-remediation-scan-mode/pdf-remediation-scan-mode.module'
      ).then((m) => m.PdfRemediationScanModeModule),
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
        pathMatch: 'full',
      },
      {
        path: 'file-upload',
        loadChildren: () =>
          import('./pages/file-upload/file-upload.module').then(
            (m) => m.FileUploadModule1
          ),
      },
      {
        path: 'api-usage',
        loadChildren: () =>
          import('./pages/api-usage/api-usage.module').then(
            (m) => m.ApiUsageModule
          ),
      },
      {
        path: 'file-uploads-count',
        loadChildren: () =>
          import('./pages/file-upload-count/file-upload-count.module').then(
            (m) => m.FileUploadCountModule
          ),
      },
      {
        path: 'exports',
        loadChildren: () =>
          import('./pages/file-export/file-export.module').then(
            (m) => m.FileExportModule
          ),
      },

      {
        path: 'users',
        loadChildren: () =>
          import('./pages/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./pages/user-profile/user-profile.module').then(
            (m) => m.UserProfileModule
          ),
      },
      {
        path: 'resources',
        loadChildren: () =>
          import('./pages/user-resources/user-resources.module').then(
            (m) => m.UserResourcesModule
          ),
      },
      {
        path: 'report',
        loadChildren: () =>
          import('./pages/generate-report/generate-report.module').then(
            (m) => m.GenerateReportModule
          ),
      },
      {
        path: 'new-tree',
        loadChildren: () =>
          import('./pages/k-tree/k-tree.module').then((m) => m.KTreeModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      // preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
